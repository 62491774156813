import React from "react"
import Layout from "../components/site/layout"
import SEO from "../components/site/seo"
import GoogleMapReact from "google-map-react"
import ContactForm from "../components/site/ContactForm"
import { FaMapMarker } from "react-icons/fa"
import * as styles from "../styles/contact.module.scss"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { isBrowser } from "../services/helper"

const placeId = "ChIJbWRFEj-Ei0cRtuFHkKmFVhU"

export default function Contact() {
  var appearAnimation = []

  React.useEffect(() => {
    if (isBrowser()) {
      gsap.registerPlugin(ScrollTrigger)
    }
    appearAnimation.forEach(element => {
      gsap.fromTo(
        element,
        { opacity: 0 },
        { opacity: 1, scrollTrigger: element, duration: 1, ease: "Circ.easeIn" }
      )
    })
  }, [])

  return (
    <Layout currentPage="contact">
      <SEO title="Contact" description="Contactez moi ou trouver un itinéraire pour venir aux Écuries
        Page de contact du centre équestre des écuries du Cha'let (chalet) à Annecy Epagny en Haute Savoie.
        Pour me contacter n'hésitez pas à me joindre sur le numéro de téléphone indiqué en bas de page.
        Balades à cheval, poney, manège et carrière olympique 20x40 m, des installations de qualité. 
        cours de poney et de chevaux."/>

      <div
        className={styles.contactDiv}
        ref={div => {
          appearAnimation.push(div)
        }}
      >
        <h1>Horaires d'ouvertures</h1>
        <div style={{ textAlign: "center" }}>
          <h2 className="pt-3">Hors vacances scolaires</h2>
          <div className={styles.hoursDetails}>
            <p>Du lundi au samedi</p>
            <p>09h00 - 20h00</p>
          </div>
          <div className={styles.hoursDetails}>
            <p>Dimanche</p>
            <p>Uniquement par téléphone</p>
          </div>

          <h2 className="pt-3">Pendant les vacances scolaires</h2>
          <div className={styles.hoursDetails}>
            <p>Du lundi au vendredi</p>
            <p>09h00 - 18h00</p>
          </div>
          <div className={styles.hoursDetails}>
            <p>Samedi</p>
            <p>13h00 - 18h00</p>
          </div>
          <div className={styles.hoursDetails}>
            <p>Dimanche</p>
            <p>Uniquement par téléphone</p>
          </div>
        </div>
      </div>

      <div
        className={`${styles.contactDiv} mt`}
        ref={div => {
          appearAnimation.push(div)
        }}
      >
        <h1>Contactez moi</h1>
        <div>
          <p
            style={{
              fontSize: "3rem",
              color: "$color-primary",
              marginBottom: 0,
              fontFamily: "Brush Script MT, Brush Script Std, cursive",
            }}
          >
            Laetitia Challut
          </p>
          <p style={{ fontSize: "2rem" }}>06.20.52.64.18</p>
        </div>
      </div>

      <div
        className={`row no-gutters`}
        ref={div => {
          appearAnimation.push(div)
        }}
      >
        <div className="col-md-12 col-lg-4">
          <div className={`${styles.panelSecondary} row no-gutters`}>
            <h2>Adresse</h2>
            <span className="small">
              Cliquer{" "}
              <a
                href={
                  "https://www.google.com/maps/dir/?api=1&destination=Centre+équestre+du+Chalet&destination_place_id=" +
                  placeId
                }
                rel="noreferrer"
                target="_blank"
              >
                ici
              </a>{" "}
              pour obtenir un itinéraire
            </span>
            <span className="mt-3">Les écuries du Cha'let</span>
            <span>235 Route de Chez les Roux</span>
            <span>74330 Epagny Metz-Tessy, France</span>

            <div className="mt-5 d-flex flex-grow-1 flex-column">
              <ContactForm returnLink={true} spinnerLoadingColor="#fff" />
            </div>
          </div>
        </div>

        <div className="col-md-12 col-lg-8 google-map">
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyAJIY6pUBCuUOJlThxLrhy6Q5XogmO-zXU",
            }}
            defaultCenter={{ lat: 45.952297, lng: 6.070126 }}
            defaultZoom={13}
          >
            <a
              lat={45.952297}
              lng={6.070126}
              href={
                "https://www.google.com/maps/dir/?api=1&destination=Centre+équestre+du+Chalet&destination_place_id=" +
                placeId
              }
              target="_blank"
              rel="noreferrer"
              key={1}
              className="googlemaps-marker"
            >
              <FaMapMarker size="3em" color="var(--color-primary)" />
            </a>
          </GoogleMapReact>
        </div>
      </div>

      <span style={{height: "1px", overflow:"hidden", display:"flex"}}>
        Contactez moi ou trouver un itinéraire pour venir aux Écuries
        Page de contact du <h1>centre équestre des écuries du Cha'let (chalet) à Annecy Epagny en Haute Savoie</h1>, très prês de la suisse et genève.
        Pour me contacter n'hésitez pas à me joindre sur le numéro de téléphone indiqué en bas de page.
        Balades à cheval, poney, manège et carrière olympique 20x40 m, des installations de qualité. 
        Cours de poney et de chevaux. Faites découvrir cette passion à votre enfant. Cheval pour enfant (chevaux pour enfants)
      </span>
    </Layout>
  )
}
